/* Weight
700
Size
30px
Line Height
45px */
.history_data_count {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
}

.sc-dnwKUv,
.gNJUsR {
  overflow: hidden;
  height: 500px;
}

.rdt_Pagination {
}
